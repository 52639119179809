<template>
  <div class="page-root tools-bzResult-root">
    <div class="bzResult-page">
      <div class="title">在线评估 > 保值率查询 > <span>查询结果</span></div>
      <div class="result-item2" v-loading="loading">
        <div class="title">车辆保值率查询</div>
        <div class="list-bar">
          <div class="list">
            <cyt-car-select
              class="hidden-md-and-down"
              :defaultValue="pcValue"
              :onlySeries="true"
              @carSelected="value => this.pcValue = value"
            />
            <van-field
              class="hidden-md-and-up"
              v-model="fieldValue"
              is-link
              readonly
              placeholder="请选择品牌/车系"
              @click="show = true"
            />
          </div>
          <div class="butt">
            <div @click="search">开始查询</div>
          </div>
        </div>
        <el-empty v-show="isempty" description="暂无数据 ！"></el-empty>
        <div v-show="!isempty" id="myChart1" style="width: 1000px; height: 400px"></div>
      </div>
      <div class="result-item2">
        <el-table size="small" :data="tableData" style="width: 100%; margin-top: 10px">
          <el-table-column prop="year" label="年限"> </el-table-column>
          <el-table-column prop="ratio" label="保值率(%)"> </el-table-column>
          <el-table-column prop="price" label="剩余价格(万元)"> </el-table-column>
        </el-table>
      </div>
      <van-popup v-model="show" round position="bottom" v-if="options.length">
        <van-cascader
          v-model="value1"
          title="品牌/车系"
          :options="options"
          @close="show = false"
          @change="onChange"
          @finish="onFinish"
        />
      </van-popup>
    </div>
  </div>
</template>
<script>
import './styles/mobile.scss';
import './styles/pc/pc.scss';
import * as echarts from 'echarts';
import { get } from '@/utils/axios';
import CytCarSelect from '@/components/CytCarSelect/CytCarSelect.vue';
export default {
  name: 'Tools-pgResult',
  components: {
    CytCarSelect,
  },
  data() {
    return {
      isempty: false,
      loading: false,
      tableData: [],
      value1: this.$route.query.seriesId,
      pcValue: [this.$route.query.brandId, this.$route.query.seriesId],
      show: false,
      fieldValue: this.$route.query.fieldValue,
      options: [],
    };
  },
  methods: {
    onChange({ value, selectedOptions, tabIndex }) {
      if (tabIndex === 0) {
        get(`/system/brand/list/s/${value}`).then((res) => {
          this.options[selectedOptions[0].index].children = res.data.map((item, i) => {
            return {
              index: i,
              text: item.vehicleSeriesName,
              value: item.vehicleSeriesId,
            };
          });
        });
      }
    },
    onFinish({ selectedOptions }) {
      this.show = false;
      this.fieldValue = selectedOptions.map((option) => option.text).join('/');
      this.pcValue = selectedOptions.map((v) => v.value);
    },
    formatNum(num) {
      return num ? (Number(num) / 10000).toFixed(2) : '0.00';
    },
    getYear(year) {
      let arr = [];
      for (var i = 1; i <= year.length; i++) {
        arr.push(`${i}年`);
      }
      return arr;
    },
    setPrice(row, ratio, i) {
      if (!parseInt(row.minprice) || !parseInt(row.maxprice)) {
        return '暂无数据';
      } else if (row.minprice == row.maxprice) {
        return this.formatNum((row.minprice * ratio[i - 1]) / 100);
      } else {
        return (
          this.formatNum((row.minprice * ratio[i - 1]) / 100) +
          ' - ' +
          this.formatNum((row.maxprice * ratio[i - 1]) / 100)
        );
      }
    },
    getRows(row) {
      let arr = [];
      let ratio = row.ratio.split(',');
      for (var i = 1; i <= ratio.length; i++) {
        arr.push({
          year: `${i}年`,
          ratio: ratio[i - 1],
          price: this.setPrice(row, ratio, i),
        });
      }
      return arr;
    },
    search() {
      if (!this.pcValue.length) {
        this.$confirm('车系未选择 !', '提示', {
          confirmButtonText: '确定',
          showCancelButton: false,
          type: 'warning',
        });
      } else {
        this.init(this.pcValue[0], this.pcValue[1]);
      }
    },
    init(brandId, seriesId) {
      this.loading = true;
      get(`/system/ratio/list?seriesId=${seriesId}`)
        .then((res) => {
          if (res.code == 200) {
            const datas = res.data;
            this.tableData = this.getRows(datas);
            var myChart1 = echarts.init(document.getElementById('myChart1'));
            myChart1.setOption({
              tooltip: {
                trigger: 'item',
                formatter: `第{b}保值率 : {c}%`,
              },
              xAxis: {
                data: this.getYear(datas.ratio.split(',')),
              },
              yAxis: {},
              series: [
                {
                  data: datas.ratio.split(','),
                  type: 'line',
                  itemStyle: {
                    normal: {
                      color: '#FBA518',
                      lineStyle: {
                        color: '#FBA518',
                      },
                    },
                  },
                  areaStyle: {
                    color: '#FBA518',
                    opacity: 0.3,
                  },
                  label: {
                    show: true,
                    position: 'bottom',
                    textStyle: {
                      value: 12,
                      fontSize: 12,
                    },
                  },
                },
              ],
            });
            this.isempty = false;
          } else {
            this.isempty = true;
            this.tableData = [];
          }
        })
        .then(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    document.getElementById('app').scrollTo(0, 0);
    const { brandId, seriesId } = this.$route.query;
    this.init(brandId, seriesId);
    //查询品牌
    get('/system/brand/list').then((res) => {
      this.options = res.rows.map((item, i) => {
        return {
          index: i,
          text: item.brandName,
          value: item.brandId,
          children: [],
        };
      });
      const brandIndex = this.options.findIndex(brand => brand.value === brandId)
      if (brandIndex === -1) {
        return;
      }
      get(`/system/brand/list/s/${brandId}`).then((res) => {
        this.options[brandIndex].children = res.data.map((item, i) => {
          return {
            index: i,
            text: item.vehicleSeriesName,
            value: item.vehicleSeriesId,
          };
        });
      });
    });
  },
};
</script>
